// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns.js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-success-js": () => import("./../../../src/pages/careers-success.js" /* webpackChunkName: "component---src-pages-careers-success-js" */),
  "component---src-pages-crown-standard-js": () => import("./../../../src/pages/crown-standard.js" /* webpackChunkName: "component---src-pages-crown-standard-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-feedback-success-js": () => import("./../../../src/pages/feedback-success.js" /* webpackChunkName: "component---src-pages-feedback-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kids-menu-js": () => import("./../../../src/pages/kids-menu.js" /* webpackChunkName: "component---src-pages-kids-menu-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-responsibility-js": () => import("./../../../src/pages/responsibility.js" /* webpackChunkName: "component---src-pages-responsibility-js" */),
  "component---src-pages-restaurants-js": () => import("./../../../src/pages/restaurants.js" /* webpackChunkName: "component---src-pages-restaurants-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-menu-product-group-page-js": () => import("./../../../src/templates/menu/ProductGroupPage.js" /* webpackChunkName: "component---src-templates-menu-product-group-page-js" */),
  "component---src-templates-menu-product-page-js": () => import("./../../../src/templates/menu/ProductPage.js" /* webpackChunkName: "component---src-templates-menu-product-page-js" */)
}

